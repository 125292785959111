// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-chat-chat-js": () => import("./../../../src/components/Chat/chat.js" /* webpackChunkName: "component---src-components-chat-chat-js" */),
  "component---src-components-watch-together-app-js": () => import("./../../../src/components/WatchTogether/app.js" /* webpackChunkName: "component---src-components-watch-together-app-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-browser-js": () => import("./../../../src/pages/browser.js" /* webpackChunkName: "component---src-pages-browser-js" */),
  "component---src-pages-chat-js": () => import("./../../../src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-naturalization-js": () => import("./../../../src/pages/naturalization.js" /* webpackChunkName: "component---src-pages-naturalization-js" */),
  "component---src-pages-quiz-builder-js": () => import("./../../../src/pages/quiz-builder.js" /* webpackChunkName: "component---src-pages-quiz-builder-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-spotify-player-js": () => import("./../../../src/pages/spotify-player.js" /* webpackChunkName: "component---src-pages-spotify-player-js" */),
  "component---src-pages-terminal-js": () => import("./../../../src/pages/terminal.js" /* webpackChunkName: "component---src-pages-terminal-js" */),
  "component---src-pages-watch-together-js": () => import("./../../../src/pages/watch-together.js" /* webpackChunkName: "component---src-pages-watch-together-js" */),
  "component---src-pages-youtify-js": () => import("./../../../src/pages/youtify.js" /* webpackChunkName: "component---src-pages-youtify-js" */)
}

